import React from 'react';
import { Col, Form, Input } from 'antd';


const InputFormTextArea = (props) => {

    return (
        <Col
            order={props.order}
            span={props.span}
            xs={props.xs}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            xl={props.xl}
            flex={props.flex}
            offset={props.offset}
            pull={props.pull}
            push={props.push}
            RoleName={props.RoleName}
        >
            <Form.Item
                className="my-form-item-input-textarea"
                label={props.label}
                name={props.name}
                // validateTrigger={['onBlur', 'onChange']}
                hasFeedback={props.hasFeedback}
                rules={[
                    { required: props.required, message: `${"Please input"} ${props.placeholder || props.label || "Note"}!` },
                    { max: props.maxLength, message: `${props.placeholder || props.label || "Note"} must be maximum ${props.maxLength} characters.` },
                    { min: props.minLength, message: `${props.placeholder || props.label || "Note"} must be minimum ${props.minLength} characters.` },
                ]}
                layout="vertical"
            >
                <Input.TextArea

                    className={props.className + ` my-input-form-textarea grayinput ` + props.InputClassName}
                    placeholder={props.placeholder || props.label || "Note"}
                    autoSize={props.autoSize}
                    onFocus={(e) => e.target.selec}
                    disabled={props.disabled}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    bordered={props.bordered}
                    rows={props.rows}
                    readOnly={props.readOnly}
                    size={props.size}
                    // prefix={<UserOutlined />}
                    // allowClear={props.allowClear}
                    onPressEnter={(e) => {
                        // if (!props.disableNextFocus) {
                        //     e.preventDefaul);
                        // }
                        // if (props.onBeforePressEnter) {
                        //     props.onBeforePressEnter();
                        //     e.persis)
                        //     if (!props.disableNextFocus) {
                        //         setTimeou() => {
                        //             UtilComponentFunctions.nextFocus(e);
                        //         }, 100);
                        //     }
                        // } else {
                        //     if (!props.disableNextFocus) {
                        //         if(props.Nextfocus){
                        //             UtilComponentFunctions.nextNextFocus(e);

                        //           }else
                        //           UtilComponentFunctions.nextFocus(e);
                        //         }

                        // }
                        // props.onPressEnter && props.onPressEnter(e);
                    }}
                />
            </Form.Item>
        </Col>
    )
}

InputFormTextArea.defaultProps = {
    // label: "Username",
    name: "comment",
    // placeholder: "Comment",
    // message: 'Please input name!',
    // type: 'url',
    autoSize: { minRows: 1, maxRows: 4 },
    required: false,
    hasFeedback: true,
    allowClear: true,
    // maxLength: 255,
    minLength: 0,
}

export default InputFormTextArea