import { Card, Col, Collapse, Row } from 'antd'
import React from 'react'

const CourseFQA = () => {

    const items = [
        {
            key: '1',
            label: 'Who is this course for?',
            children: <p>{ }</p>,
        },
        {
            key: '2',
            label: 'What are the prerequisites for this course?',
            children: <p>{ }</p>,
        },
        {
            key: '3',
            label: 'Is the course live or pre-recorded?',
            children: <p>{ }</p>,
        },
    ];
    return (
        <Row className='CourseFQA'>

            <Col span={14} >
                <Card className='cusCard'>
                                    <Collapse 
                                        items={items} 
                                        defaultActiveKey={['1']} 
                                        expandIconPosition="end"
                                    />
                </Card>
            </Col>
        </Row>
    )
}

export default CourseFQA