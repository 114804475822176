import Parse from 'parse/dist/parse.min.js';
import { fetchFromUrlGETAsync } from '../utils/UrlHelper';




const COURSES_LIST = "/courses"



export const getCoursesList = async (params) => {
    const urlParams = [];
    if (params) {
        for (let index = 0; index < Object.keys(params).length; index++) {
            const element = Object.keys(params)[index];
            let obj = { key: element, value: params[element] }
            urlParams.push(obj)
        }
    } 
    
    let result = await fetchFromUrlGETAsync(COURSES_LIST ,urlParams);
    return result;
}


// Function to get a paginated list of courses with additional filtering options
export const getCourses = async (params) => {
    try {
        const res = await Parse.Cloud.run("getCourses", params);
        if (res?.success) {
            return { ...res }
        } else {
            return { ...res }
        }
    } catch (error) {
        console.error("Error fetching home page content:", error);
        return { success: false, message: error }

    }
};

export const getCourseById = async (courseId) => {
    if (!courseId) {
        return {
            success: false,
            message: "Course ID is required"
        };
    }

    try {
        const response = await Parse.Cloud.run("getCourseById", { courseId });
        return response;
    } catch (error) {
        console.error("Error fetching course:", error);
        return {
            success: false,
            message: error?.message
        };
    }
};




export const subtitlesCourse = {
    arabic: 1,
    english: 2,
    french: 3
}

export const coursesLevel = {
    beginner: 1,
    intermediate: 2,
    advanced: 3,
    expert: 4,
}

export const coursesPriceType = {
    free: 1,
    paid: 2,

}

export const courseFormat = {
    live: 1,
    recorded: 2,

}

export const subtitlesArray = [
    { label: "Arabic", value: subtitlesCourse.arabic },
    { label: "English", value: subtitlesCourse.english },
    { label: "French", value: subtitlesCourse.french },
]




export const coursesLevelArray = [
    {
        label: "Beginner",
        value: coursesLevel.beginner
    },
    {
        label: "Intermediate",
        value: coursesLevel.intermediate
    },
    {
        label: "Advanced",
        value: coursesLevel.advanced
    },
    {
        label: "Expert",
        value: coursesLevel.expert
    },

]



export const courseFormatArray = [
    {
        label: "Live",
        value: courseFormat.live
    },
    {
        label: "Recorded",
        value: courseFormat.recorded
    },


]

export const getSubtitleLabel = (id) => {
    const subtitle = subtitlesArray.find(item => item.value == id)
    return subtitle?.label
}

export const getCoursesFormatLabel = (id) => {
    const CoursesFormatFind = courseFormatArray.find(item => item.value == id)
    return CoursesFormatFind?.label
}

export const getCoursesLevelLabel = (id) => {
    const CoursesLevelFind = coursesLevelArray.find(item => item.value == id)
    return CoursesLevelFind?.label
}


