import { Col, Divider, Form, Radio, Row, Select, Tag } from 'antd'
import React, { useState } from 'react'
import FormSelect from '../../../../components/formSelect/FormSelect'
import InputFormText from '../../../../components/inputFormText/InputFormText'
import InputFormTextArea from '../../../../components/inputFormTextArea/InputFormTextArea'
import Title from 'antd/es/typography/Title'

const Step3 = () => {
  const [value, setValue] = useState('Yes');
  const [selectedDays, setSelectedDays] = useState([]);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const [selectedShifts, setSelectedShifts] = useState([]);
  const shifts = ['Morning Shift', 'Afternoon Shift', 'Late Afternoon Shift', 'Evening Shift'];


  return (
    <Form >
      <>
        <Row
          gutter={[16, 16]}
        >



          <Col span={18}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title className='color-darkGrey font-18 bold-400'>* Which days are you available to work?</Title>
              </Col>
              {days.map(day => (
                <Tag
                  key={day}
                  className={`cusTag ${selectedDays.includes(day) ? 'selected-tag' : ''}`}
                  onClick={() => setSelectedDays(prev =>
                    prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
                  )}
                >
                  {day}
                </Tag>
              ))}




              <Col span={24}>
                <Title className='color-darkGrey font-18 bold-400'>* What times are you available to work?</Title>
              </Col>

              {shifts.map(shift => (
                <Tag
                  key={shift}
                  className={`cusTag ${selectedShifts.includes(shift) ? 'selected-tag' : ''}`}
                  onClick={() => setSelectedShifts(prev =>
                    prev.includes(shift) ? prev.filter(s => s !== shift) : [...prev, shift]
                  )}
                >
                  {shift}
                </Tag>
              ))}


            </Row>

            <Col span={18}>
              <Row>

              </Row>
            </Col>



          </Col>


          <FormSelect
            size='large'
            span={18}
            label="What is your teaching specialty?"
          />


          <InputFormText
            size='large'

            label="What is the highest degree you hold?"
            name={"firstName"}

            span={18}
            validateTrigger={['onBlur']}

          />




          <InputFormTextArea
            size='large'
            // className={'InputFormTextArea-description'}
            span={18}
            label='What courses or subjects have you taught?'
            name="description"
            minLength={1}
            required
          // autoSize={{ minRows: 5, maxRows: 10 }}
          />


          <Col span={24}>
          </Col>
          <Col span={18}>
            <Title className='color-darkGrey font-18 bold-400'>* Do you have a personal curriculum for teaching these subjects?</Title>

            <Form.Item>

              <Radio.Group

                // onChange={onChange} 
                value={value}>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>

              </Radio.Group>
            </Form.Item>
          </Col>

          <Col span={18}>
            <Title className='color-darkGrey font-18 bold-400'>* Have you taught online or virtually before?</Title>

            <Form.Item>

              <Radio.Group

                // onChange={onChange} 
                value={value}>
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>

              </Radio.Group>
            </Form.Item>
          </Col>


          <InputFormTextArea
            size='large'
            // className={'InputFormTextArea-description'}
            span={18}
            label='* What programs or tools do you use for virtual teaching?'
            name="description"
            minLength={1}
            required
          // autoSize={{ minRows: 5, maxRows: 10 }}
          />
          <InputFormTextArea
            size='large'
            // className={'InputFormTextArea-description'}
            span={18}
            label='* What courses or subjects would you like to teach?'
            name="description"
            minLength={1}
            required
          // autoSize={{ minRows: 5, maxRows: 10 }}
          />

          <InputFormText
            size='large'

            label="What hourly rate would you like to receive for teaching? ($)?"
            name={"firstName"}

            span={18}
            validateTrigger={['onBlur']}

          />

        </Row>

      </>
    </Form>
  )
}

export default Step3