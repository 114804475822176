import { Button, Card, Col, Image, Row, Tag } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { FaStar } from "react-icons/fa";
import { HiMiniSignal } from "react-icons/hi2";
import { MdOutlineEventSeat } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { generateCourseCode } from '../../utils'
import { getCoursesFormatLabel } from "../../actions/CoursesActions";

export
    const CourseCard = (props) => {
        const { data } = props;
        const navigate = useNavigate();


        return (
            <Card className='myCard'>
                <Image src={data?.cover_image} className='cardImag ' width={'100%'} height={240} preview={false} />

                <svg className='logo' width="45" height="48" viewBox="0 0 45 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.01155 45.8366C1.15738 45.8272 0.464766 45.1417 0.446439 44.2877L0.000401905 23.5029C-0.0301229 22.0805 1.6853 21.343 2.69655 22.3438L23.9308 43.3586C24.9421 44.3594 24.2224 46.0824 22.7998 46.0667L2.01155 45.8366Z" fill="url(#paint0_linear_382_67)" />
                    <path d="M1.16622 15.5424C1.05341 15.4283 0.98813 15.2756 0.983585 15.1152L0.621215 2.3252C0.605909 1.78498 1.04382 1.34088 1.5842 1.3486L14.7165 1.53629C14.8826 1.53866 15.041 1.60617 15.1578 1.72427L27.3649 14.0728C27.9796 14.6945 27.9738 15.6968 27.352 16.3114L15.6186 27.9105C14.9969 28.5252 13.9946 28.5194 13.38 27.8976L1.16622 15.5424Z" fill="url(#paint1_linear_382_67)" />
                    <path d="M17.6199 31.7335L31.0938 17.8959L44.495 30.9449C41.5971 36.7578 36.9828 41.5397 31.277 44.6432L31.021 44.7825L17.6199 31.7335Z" fill="url(#paint2_linear_382_67)" />
                    <path d="M39.9703 20.0634L26.0487 6.65658L21.0796 1.07617L24.5378 1.07617C28.9481 1.07617 33.2478 2.45593 36.8345 5.02212L37.0685 5.18947C38.4295 6.16326 39.6557 7.31288 40.7151 8.6084C43.717 12.2792 45.2351 16.9411 44.9704 21.6756L44.7848 24.9948L39.9703 20.0634Z" fill="url(#paint3_linear_382_67)" />
                    <defs>
                        <linearGradient id="paint0_linear_382_67" x1="41.3621" y1="22.56" x2="10.6617" y2="34.4391" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0FB3ED" />
                            <stop offset="0.996" stop-color="#0096D8" />
                            <stop offset="1" stop-color="#0096D8" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_382_67" x1="45" y1="23.5715" x2="0" y2="23.5715" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#565656" />
                            <stop offset="1" stop-color="#8C8383" />
                        </linearGradient>
                        <linearGradient id="paint2_linear_382_67" x1="10.3235" y1="46.0668" x2="42.7015" y2="31.1644" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0FB3ED" />
                            <stop offset="1" stop-color="#164EAB" />
                        </linearGradient>
                        <linearGradient id="paint3_linear_382_67" x1="28.517" y1="1.15502" x2="34.1174" y2="24.8384" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFD056" />
                            <stop offset="1" stop-color="#FFB400" />
                        </linearGradient>
                    </defs>
                </svg>
                <div className='p-3'>
                    <Row justify={'space-between'} align={'bottom'}>
                        <Col >
                            {/* <Title className='bold-600 font-14 color-darkGrey'>{data?.category}</Title> */}
                            <Row justify={'space-between'} align={'middle'} gutter={10}>
                                <Col>
                                    <HiMiniSignal className='me-2 color-primary' size={16} />
                                    {data?.course_format ? getCoursesFormatLabel(data?.course_format) : `unknown`
                                    }
                                </Col>
                                <Col>
                                    <MdOutlineEventSeat className='me-2 color-primary' size={16} />
                                    {data?.max_seats_available ? data?.max_seats_available : 0}
                                </Col>
                            </Row>

                        </Col>
                        <Col span={3}>
                            <FaStar color='#FFB400' size={20} />
                            <span className='bold-600 font-14 color-darkGrey mt-3 ms-2'>{data?.rate}</span>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        {data?.programs_objects?.length > 0 ? (
                            data?.programs_objects.map((program, index) => (
                                <Tag key={index} className='cusTag mt-1'>{program?.title}</Tag>
                            ))
                        ) : <Tag className='cusTag mt-1'>{data?.program_object?.title}</Tag>}

                    </Row>

                    <Row justify={'space-between'} align={'middle'}>
                        <Col>          <Title className='bold-700 font-16 color-darkGrey mt-2'>{data?.title}</Title>
                        </Col>
                        <Col>
                            {generateCourseCode(data)}
                        </Col>
                    </Row>



                    <Paragraph
                        ellipsis={{
                            rows: 2,
                            expandable: 'collapsible'
                        }}
                        className='bold-400 font-14 color-darkGrey mt-2'>{data?.description}</Paragraph>
                    <Title className='color-grey bold-500 font-12'>{data?.duration} Hours</Title>
                    <Row justify={'space-between'} align={'middle'} className='mt-3'>
                        <Title className='bold-500 font-16 color-darkGrey'>${data?.price}</Title>
                        <Col span={10}>
                            <Button
                                className='cusBtn'
                                block
                                onClick={() => navigate(`/course/${data?.id}`)}

                            >
                                More Details
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Card>

        );
    };