import { Col, Form, Select } from "antd";
import React from "react";
// import './FormSelect.scss'
const FormSelect = (props) => {
  const { span, xl, xs, className, formItemClassName, ...formProps } = props;
  const { name, required, placeholder, label, inputClassName, ...inputProps } =
    formProps;
  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${"please Select a valid value! "} ${
        placeholder ? placeholder : label
      }!`;
  const rules = [
    {
      required: required,
      message: errorMessage,
    },
  ];
  return (
    <Col span={span} {...props} className={className}>
      <Form.Item
        className={formItemClassName}
        name={name}
        required={required}
        placeholder={placeholder}
        label={label}
        rules={rules}
      >
        <Select className={`Select ${inputClassName} `} 
        tagRender={props.tagRender}
        {...inputProps}>
          {props.options &&
            Array.from(props.options).map((item, index) => {
              return (
                <Select.Option
                  key={index}
                  value={item.value}
                  label={label}
                >
                  {props.renderOption
                    ? props.renderOption(item, index)
                    : item.label}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>
    </Col>
  );
};
FormSelect.defaultProps = {
  hasFeedback: true,
  showSearch: true,
  required: true,
  options: [],
  autoFocus: false,
  defaultActiveFirstOption: false,
  bordered :true
};

export default FormSelect;
