import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from '../pages/landing/Landing'
import MainLayout from '../layout/mainLayout/MainLayout'
import Hero from '../pages/landing/Hero'
import About from '../pages/landing/About'
import Account from '../pages/landing/Account'
import Opinions from '../pages/landing/Opinions'
import Programs from '../pages/landing/Programs'
import SingleProgram from '../pages/singleProgram/SingleProgramList'
import SingleCourse from '../pages/singleCourse/SingleCourse'
import ScrollToTop from './ScrollToTop'
import CoursesListAkram from '../pages/singleCourse/akramCourses/CoursesListAkram'
import SignIn from '../pages/auth/signIn/SignIn'
import ForgotPassword from '../pages/auth/signIn/ForgotPassword'
import SignUp from '../pages/auth/signUp/SignUp'
import Resetpassword from '../pages/auth/signIn/Resetpassword'
import SingleCourseAkram from '../pages/singleCourse/akramCourses/SingleCourseAkram'

const AppRoute = () => {
    return (

        <>
            <ScrollToTop />
            <Routes>

                <Route
                    exact
                    path="/"
                    index
                    element={<MainLayout>
                        <Landing />
                    </MainLayout>}
                />



                <Route
                    exact
                    path="/program/:programId"
                    index
                    element={<MainLayout>
                        <SingleProgram />
                    </MainLayout>}
                />


                <Route
                    exact
                    path="/course/:courseId"
                    index
                    element={<MainLayout>
                        <SingleCourse />
                    </MainLayout>}
                />

                <Route
                    exact
                    path="/akramCourses"
                    index
                    element={<MainLayout>
                        <CoursesListAkram />
                    </MainLayout>}
                />

                <Route
                    exact
                    path="/SignIn"
                    index
                    element={<MainLayout>
                        <SignIn />
                    </MainLayout>}
                />
                <Route
                    exact
                    path="/auth/signIn/forgetPassword"
                    index
                    element={<MainLayout>
                        <ForgotPassword />
                    </MainLayout>}
                />
                <Route
                    exact
                    path="/signUp"
                    index
                    element={<MainLayout>
                        <SignUp />
                    </MainLayout>}
                />
                <Route
                    exact
                    path="/auth/signIn/Resetpassword"
                    index
                    element={<MainLayout>
                        <Resetpassword />
                    </MainLayout>}
                />

                     
            <Route
                    exact
                    path="/akramCourse/details/:courseId"
                    index
                    element={<MainLayout>
                        <SingleCourseAkram />
                    </MainLayout>}
                />


            </Routes>

       
        </>


    )
}

export default AppRoute