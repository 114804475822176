import { Tabs } from 'antd'
import React from 'react'
import CourseOverview from './courseInfos/CourseOverview';
import CourseOutcomes from './courseInfos/CourseOutcomes';
import CourseCurriculum from './courseInfos/CourseCurriculum';
import CourseFQA from './courseInfos/CourseFQA';
import CourseInstructor from './courseInfos/CourseInstructor';
import CourseReviews from './courseInfos/CourseReviews';

const CourseDescription = ({data}) => {

    const items = [
        {
          key: '1', 
          label: 'Overview',
          children: <CourseOverview data={data} />,
        },
        {
          key: '2', 
          label: 'Instructor',
          children: <CourseInstructor data={data} />,
        },
        {
          key: '3',
          label: 'Curriculum',
          children: <CourseCurriculum data={data} />,
    
        },
        {
          key: '4',
          label: 'FAQs',
          children: <CourseFQA />,
        },
        {
          key: '5',
          label: 'Reviews',
          children: <CourseReviews />,
        },
      
      ];
  return (
    <div className=' CourseDescription myContainer'>
        <Tabs className='customTabs' items={items} />
    </div>
  )
}

export default CourseDescription