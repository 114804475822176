import { Button, Card, Col, Image, Row, Tag } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/es/typography/Title'
import React, { useEffect, useState } from 'react'
import { FaStar } from 'react-icons/fa'
import { HiMiniSignal } from 'react-icons/hi2'
import { getCourses, getCoursesFormatLabel } from '../../../actions/CoursesActions'
import { MdOutlineEventSeat } from 'react-icons/md'
import { generateCourseCode } from '../../../utils'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';

const RecommendedCourses = (props) => {
    const { data } = props


    return (
        <Row className='mt-4'>

            <Col span={24}>
                <Title className='color-darkGrey bold-600 font-24 line-32'>Recommended Courses</Title>
            </Col>
            <Swiper
                modules={[Navigation]}
                navigation
                spaceBetween={30}
                slidesPerView={3.5}
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 3,
                    },
                }}
            >
                <SwiperSlide>
                    <Card className='myCard'>
                        <Image src={data?.cover_image} className='cardImag ' width={'100%'} height={240} preview={false} />
                        <svg className='logo' xmlns="http://www.w3.org/2000/svg" width="33" height="21" viewBox="0 0 33 21" fill="none">
                            {/* SVG paths remain unchanged */}
                        </svg>
                        <div className='p-3'>
                            <Row justify={'space-between'} align={'bottom'}>
                                <Col >
                                    {/* <Title className='bold-600 font-14 color-darkGrey'>{data?.category}</Title> */}
                                    <Row justify={'space-between'} align={'middle'} gutter={10}>
                                        <Col>
                                            <HiMiniSignal className='me-2 color-primary' size={16} />
                                            {data?.course_format ? getCoursesFormatLabel(data?.course_format) : `unknown`
                                            }
                                        </Col>
                                        <Col>
                                            <MdOutlineEventSeat className='me-2 color-primary' size={16} />
                                            {data?.max_seats_available ? data?.max_seats_available : 0}
                                        </Col>
                                    </Row>

                                </Col>
                                <Col span={3}>
                                    <FaStar color='#FFB400' size={20} />
                                    <span className='bold-600 font-14 color-darkGrey mt-3 ms-2'>{data?.rate}</span>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                {data?.programs_objects?.length > 0 ? (
                                    data?.programs_objects.map((program, index) => (
                                        <Tag key={index} className='cusTag mt-1'>{program?.title}</Tag>
                                    ))
                                ) : <Tag className='cusTag mt-1'>{data?.program_object?.title}</Tag>}

                            </Row>

                            <Row justify={'space-between'} align={'middle'}>
                                <Col>          <Title className='bold-700 font-16 color-darkGrey mt-2'>{data?.title}</Title>
                                </Col>
                                <Col>
                                    {generateCourseCode(data)}
                                </Col>
                            </Row>



                            <Paragraph
                                ellipsis={{
                                    rows: 2,
                                    expandable: 'collapsible'
                                }}
                                className='bold-400 font-14 color-darkGrey mt-2'>{data?.description}</Paragraph>
                            <Title className='color-grey bold-500 font-12'>{data?.duration} Hours</Title>
                            <Row justify={'space-between'} align={'middle'} className='mt-3'>
                                <Title className='bold-500 font-16 color-darkGrey'>${data?.price}</Title>
                                <Col span={10}>
                                    <Button
                                        className='cusBtn'
                                        block
                                        onClick={() => navigate(`/course/${data?.id}`)}

                                    >
                                        More Details
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </SwiperSlide>
            </Swiper>




        </Row>
    )
}

export default RecommendedCourses