import { Button, Card, Col, Image, Row, Tag } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import { FaChalkboardTeacher, FaStar } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { HiMiniSignal } from "react-icons/hi2";
import { MdOutlineEventSeat } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getImageUrl } from "../../../utils";

export const CourseAkramCard = (props) => {
        const { getCategoryNameByID, data ,getInstructorNameById } = props;
        const navigate = useNavigate();
 
          
        return (
            <Card className='myCard'>
                <Image
                fallback={`https://picsum.photos/id/${data?.id}/300/200`}
                src={getImageUrl(data?.thumbnail)} className='cardImag ' width={'100%'} height={240} preview={false} />
                <svg className='logo' xmlns="http://www.w3.org/2000/svg" width="33" height="21" viewBox="0 0 33 21" fill="none">
                    {/* SVG paths remain unchanged */}
                </svg>
                <div className='p-3'>
                    <Row justify={'space-between'} align={'bottom'}>
                        <Col >
                            <Row justify={'space-between'} align={'middle'} gutter={10}>
                                <Col>
                                    <FaChalkboardTeacher className='me-2 color-primary' size={16} />
                                    {getInstructorNameById && getInstructorNameById(data?.instructor_id,1)}
                                </Col>
                                <Col>
                                    <MdOutlineEventSeat className='me-2 color-primary' size={16} />
                                    {data?.capacity ? data?.capacity : 0}
                                </Col>
                            </Row>

                        </Col>
                        <Col span={3}>
                            <FaStar color='#FFB400' size={20} />
                            <span className='bold-600 font-14 color-darkGrey mt-3 ms-2'>{data?.rate}</span>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                    <Tag  className='cusTag mt-1'>{getCategoryNameByID && getCategoryNameByID(data?.category_id,1)}</Tag>
                    </Row>

                    <Row justify={'space-between'} align={'middle'}>
                        <Col>          <Title className='bold-700 font-16 color-darkGrey mt-2'>{data?.title}</Title>
                        </Col>
                        <Col>
                            {/* {generateCourseCode(data)} */}
                        </Col>
                    </Row>



                    <Paragraph
                        ellipsis={{
                            rows: 2,
                            expandable: 'collapsible'
                        }}
                        style={{height:45}}
                        className='bold-400 font-14 color-darkGrey mt-2'>{
                            data?.seo_description
                            // <div
                            // dangerouslySetInnerHTML={{
                            //     __html: data?.description
                            // }}
                            // />

                        }</Paragraph>
                    <Title className='color-grey bold-500 font-12'>{Math.floor(data?.duration/60)} Hours</Title>
                    <Row justify={'space-between'} align={'middle'} className='mt-3'>
                        <Title className='bold-500 font-16 color-darkGrey'>${data?.price}</Title>
                        <Col span={10}>
                            <Button
                                className='cusBtn'
                                block
                                // disabled
                                onClick={() => navigate(`/akramCourse/details/${data?.id}`,{state:{course:data ,
                                    instructor : getInstructorNameById(data?.instructor_id,2),
                                    category : getCategoryNameByID(data?.category_id,2),


                                 }})}
                                
                            >
                                More Details
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Card>

        );
    };