import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import CourseDescription from '../CourseDescription';
import { Button, Col, Image, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { FaChalkboardTeacher } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import { MdOutlineSignalCellularAlt } from 'react-icons/md';
import { RiGraduationCapFill } from 'react-icons/ri';
import CourseAkramOverview from './CourseAkramOverview';
import { GoClockFill } from 'react-icons/go';
import { getImageUrl } from '../../../utils';


const IconRow = ({ icon, title }) => {
    return (
        <Col>
            <Row align={'middle'} className='mt-5 mb-5' gutter={5}>
                <Col >
                    {icon}
                </Col>
                <Col >
                    <Title className='color-white bold-400 font-16 mb-0'>{title}</Title>
                </Col>
            </Row>
        </Col>
    );
};


const SingleCourseAkram = () => {
    const { courseId } = useParams();
    const location = useLocation()

    const [courseData, setCourseData] = useState();


    useEffect(() => {
        if (location?.state) {
            console.log("location?.state",location?.state);
            
            setCourseData(location?.state)
        }
    }, [location])

    return (
        <div className='SingleCourse '>
            <div className='SingleCourseHero myContainer'>
                <Row
                    gutter={[24, 10]}
                    justify={'space-between'}
                >
                    <Col xl={12} lg={13} md={13} sm={24} xs={24}>
                        <Title className='color-white bold-600 font-40'>{courseData?.course?.title}</Title>
                        <Title className='color-white bold-400 font-24'>{courseData?.note}</Title>

                        <Row justify={'space-between'}>
                            <IconRow
                                icon={<FaChalkboardTeacher color='#FFB400' size={24} />}
                                title={courseData?.instructor?.name
                                }
                            />
                            <IconRow
                                icon={<RiGraduationCapFill color='#FFB400' size={24} />}
                                title={`${courseData?.course?.enrollments_count} +Students`}
                            />
                            <IconRow
                                icon={<MdOutlineSignalCellularAlt color='#FFB400' size={24} />}
                                title={courseData?.category?.slug}
                            />
                            <IconRow
                                icon={<GoClockFill color='#FFB400' size={24} />}
                                title={`${Math.floor(courseData?.course?.duration / 60)} Hours`}
                            />
                        </Row>
                        <Button type='primary' rootClassName='cusBtn'><span>Enroll Now</span>
                            <span>${courseData?.course?.price}</span> </Button>
                        <Button type='link' className='color-white' > <span></span>Save for Later</Button>
                    </Col>
                    <Col xl={11} lg={10} md={10} sm={24} xs={24}>
                        <div style={{ aspectRatio: '16/9' }}>
                            {courseData?.course?.demo_video_source ?
                                <ReactPlayer
                                    url={courseData?.course?.demo_video_source}
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                    playing={false}
                                    className="radius-8"
                                />
                                :

                                <Image
                                    src={getImageUrl(courseData?.course?.thumbnail)}
                                    alt=''
                                    className='cusImg radius-8'
                                    width={'100%'}
                                    rootClassName='fullHight'
                                    preview={false}
                                    fallback={`https://picsum.photos/id/${courseData?.course?.id}/300/200`}

                                />
                            }


                        </div>
                    </Col>
                </Row>
            </div>
            <CourseAkramOverview data={courseData} />
        </div>
    )
}

export default SingleCourseAkram


