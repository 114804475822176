
export const hotKeysOptions = { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'] };


export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (e) {
    return false;
  }
}



export const isObjEmpty = obj => Object.keys(obj).length === 0

export const compareOnKeyHtL = (key, type_of) => {
  if (type_of === 'date') {
    return function (a, b) {
      a = new Date(a[key]);
      b = new Date(b[key]);
      if (a < b) return 1;
      if (a > b) return -1;
      return 0;
    };
  } else {
    return function (a, b) {
      a = parseInt(a[key], 10);
      b = parseInt(b[key], 10);
      if (a < b) return 1;
      if (a > b) return -1;
      return 0;
    };
  }
}


export const MakeShortCutName = (Name) => {
  let chortcut = null
  if (Name) {
    chortcut = Name?.split(" ").map((word) => word[0]).join("");
  }
  return chortcut

}


export function urlImgPublic(fileName) {
  return process.env.PUBLIC_URL + "/icons/" + fileName;
}

export const isNotEmptyObject = (obj) => {
  return (
    obj &&
    Boolean(Object.keys(obj).length > 0));
}



export function timeSince(date) {
  const d = new Date(date);

  let ms = d.getTime();

  var seconds = Math.floor((new Date() - ms) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + "month ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "minits ago";
  }
  return Math.floor(seconds) + "secound ago";
}


export const getContractTypeName = (value) => {
  switch (value) {
    case 1:
      return ("Voyage Charter")
    case 2:
      return ("Time")
    case 3:
      return ("Bear boat")
    case 4:
      return ("COA")
    default:
      break;
  }
}

export const checkExtinctions = (value) => {
  if (value) {
    let result = value.substring(value.length - 3);
    return result
  }
}

export const checkIfImage = (value) => {
  switch (value.toUpperCase()) {
    case "PNG":
    case "PEG":
    case "JPG":
    case "SVG":
    case "BMP":
      return (true)
    default:
      return (false)
  }
}

const isPropValuesEqual = (subject, target, propNames) =>
  propNames.every(propName => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items, propNames) =>
  items.filter((item, index, array) =>
    index === array.findIndex(foundItem => isPropValuesEqual(foundItem, item, propNames))
  );



export const getUniqueItemsByPropertiesandsumprupunequal = (items, propNames) => {
  let result = items.reduce(function (r, a) {
    r[propNames.map((prop) => {
      if (typeof prop === 'string') {
        return a[prop]
      } else { return a[prop[0]][prop[1]] }
    })] = r[

    propNames.map((prop) => {
      if (typeof prop === 'string') {
        return a[prop]
      } else { return a[prop[0]][prop[1]] }
    })
    ] || [];
    r[propNames.map((prop) => {
      if (typeof prop === 'string') {
        return a[prop]
      } else { return a[prop[0]][prop[1]] }
    })].push(a);
    return r;
  }, Object.create(null));
  return result
}

export function getUniqueItemsByValue(items) {
  const uniqueIds = new Set(items.map((item) => item.value))

  const itemsWithUniqueIds = [...uniqueIds].map(value => items.find(item => item.value === value)).filter(Boolean)

  return itemsWithUniqueIds
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
let timeoutId;

export function debounce(callback, delay) {
  return function () {
    const args = arguments;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback.apply(null, args);
    }, delay);
  };
}

export function groupBy(array, key) {
  // Step 1: Group by key
  const groupedObj = array.reduce((accumulator, currentItem) => {
    // If the accumulator doesn't have a property for this key, create it
    if (!accumulator[currentItem[key]]) {
      accumulator[currentItem[key]] = [];
    }
    // Append the current item to the array for this key
    accumulator[currentItem[key]].push(currentItem);
    return accumulator;
  }, {});
  // Step 2: Transform into desired array format
  const resultArray = Object.keys(groupedObj).map(keyValue => ({
    key: keyValue,
    items: groupedObj[keyValue],
  }));

  return resultArray;
}

export function removeHtmlTags(html) {
  if (html) {
    return html.replace(/<[^>]*>/g, '');
  }
}

export function convertStringToSlug(value) {
  if (value) {
    return value.replace(/\s+/g, '_').toLowerCase();
  } else {
    return ''
  }
}

export const areStringsEqualIgnoreCase = (str1, str2) => {
  if (typeof str1 !== 'string' || typeof str2 !== 'string') {
    return false;
  }
  return str1.toLowerCase() === str2.toLowerCase();
};

export const generateCourseCode = (courseData) => {
  if (courseData?.level && courseData?.code) {
    if (courseData?.program_code) {
      return `${courseData?.program_code}-${courseData?.level}-${courseData?.code}`
    } else {
      return `SHD-${courseData?.level}-${courseData?.code}`
    }
  }
  return "No Code"
};


export const getImageUrl = (image) => {
  if (image) {
    return `${"https://learnata.heliumcloud.co/"}${image}`
  }
  return null
}


export const uploadAcceptFiles = "image/*,.pdf,.xlsx,.xls,.doc,.docx"