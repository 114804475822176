import { Col, Form, Input } from "antd";
import React from "react";
import { GoShieldSlash } from "react-icons/go";

const InputFormConfirmPassword = (props) => {
  const { span, xl, xs, className, formItemClassName, ...formProps } = props;
  const {
    name,
    required,
    placeholder,
    prefix,
    label,
    inputClassName,
    withoutError,
    ...inputProps
  } = formProps;
  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${"please input a valid password! "} ${
        placeholder ? placeholder : label
      }!`;
  const rules = [
    {
      required: required,
      message: errorMessage,
    },
  ];
  return (
    <Col span={span} xl={xl} xs={xs} className={className}>
      <Form.Item
        className={formItemClassName}
        name={name}
        required={required}
        placeholder={placeholder}
        label={label}
        hasFeedback={props.hasFeedback}
        dependencies={[props.passwordToConfirm]}
        rules={[
          { required: props.required, message: !withoutError ? errorMessage : "", },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue(props.passwordToConfirm) === value) {
                return Promise.resolve();
              }
              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password
          className={inputClassName}
          prefix={prefix}
          {...inputProps}
        />
      </Form.Item>
    </Col>
  );
};
InputFormConfirmPassword.defaultProps = {
  // prefix:<GoShieldSlash  color="#A4A4A4" size={24}/>,
  required: true,
  hasFeedback: true,
  allowClear: true,
  autoComplete: "on",
  passwordToConfirm: 'password',
  bordered: true,

};

export default InputFormConfirmPassword;
