import { Avatar, Card, Col, Divider, Image, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import vector from '../../../images/Vector.png'

const CourseInstructor = (props) => {
    const { data } = props
  return (
    <Row className='CourseCurriculum'>
          <Col span={12}>
                <Card className='cusCard'>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Title className='color-darkGrey bold-700 font-18 line-32'>
                                Instructor
                            </Title>
                        </Col>

                        <Col span={24}>
                            <Row align={'middle'} gutter={[16, 0]}>
                                <Col>
                                    <Avatar src={data?.instructor?.profile_image} size={52} />
                                </Col>
                                <Col>


                                    <Title className='color-darkGrey bold-500 font-18 line-32'>
                                        Dr. Alex Greenfield
                                    </Title>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={20}>
                            Ph.D. in Environmental Engineering, with over 15 years of experience in sustainable transportation research and urban mobility solutions.
                        </Col>

                        <Divider color='#FFB40033' />

                        <Col span={24}>
                            <Title className='color-darkGrey bold-700 font-18 line-32'>
                                Offered by
                            </Title>
                        </Col>

                        <Col span={24}>
                            <Row align={'middle'} gutter={[16, 0]}>
                                <Col >
                                    <div className='logo'>
                                        <Image src={vector} size={48} />
                                    </div>

                                </Col>
                                <Col>

                                    <Title className='color-darkGrey bold-500 font-18 line-32'>
                                        Great Lakes Online
                                    </Title>

                                </Col>
                            </Row>
                        </Col>


                    </Row>

                </Card>
            </Col>


    </Row>
  )
}

export default CourseInstructor