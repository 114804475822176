import { Avatar, Card, Col, Divider, Rate, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { BiShare } from 'react-icons/bi'

const CourseReviews = () => {
    return (
        <Row className='CourseFQA CourseReviews'>

            <Col span={14} >
                <Card className='cusCard'>

                    <Title className='color-darkGrey bold-600 font-24'>
                        comments
                    </Title>
                    <Title className='color-darkGrey bold-600 font-24'>
                        4.0
                        <Rate/>

                    </Title>
             
                    
                    <Row gutter={16} align={'top'}>
                        <Divider />

                        <Col><Avatar size={60} />

                        </Col>
                        <Col span={20}>

                            <Row justify={'space-between'} align={'bottom'}>
                                <Col>
                                    <Title className='color-darkGrey bold-600 font-16'>Sarah Johnson</Title>
                                </Col>

                                <Col>
                                    <Title className='color-grey bold-400 font-16'>October 03, 2024</Title>

                                </Col>
                                <Col span={24}>
                                    <Title className='color-darkGrey bold-400 font-18'>This course exceeded my expectations.
                                        The modules were detailed and engaging, covering everything from electric vehicles to
                                        urban planning strategies. I feel much more equipped to contribute to
                                        sustainable projects in my field. Highly recommended!</Title>
                                </Col>




                            </Row>
                            <BiShare color='#FFB400' size={20} className='me-1' /> <span className='color-grey bold-400 font-16'>Reply</span>

                        </Col>
                    </Row>

                </Card>
            </Col>
        </Row>
    )
}

export default CourseReviews