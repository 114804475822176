import React, { useEffect, useState } from 'react'
import SingleCourseHero from './SingleCourseHero'
import CustomBreadcrumb from '../../components/customBreadcrumb/CustomBreadcrumb'
import { useParams } from 'react-router-dom';

import CourseDescription from './CourseDescription';
import { getCourseById } from '../../actions/CoursesActions';



const SingleCourse = () => {
    const { courseId } = useParams();
    const [courseData, setCourseData] = useState();

    const fillCourseData = async () => {
        let result = await getCourseById(courseId)
        if (result?.success) {
            console.log("fillCourseData",result?.data);
            setCourseData(result.data);
      
            
        }
    }

    let fetch = true
    useEffect(() => {
        if(fetch && courseId){
            fillCourseData()
        }

        return () => {
            fetch  = false
        };
    }, [courseId])



    // const course = courseData[id];
    return (
        <div className='SingleCourse'>
            {/* <CustomBreadcrumb
                pathList={[
                    { name: 'Home', link: '/' },
                    { name: 'Programs', link: '/programs' },
                    { name: 'Courses', link: '/courses' }
                ]}
                currentPage={course ? course.title : 'Course Details'}
            /> */}

            <SingleCourseHero
             data={courseData} 
             />
            <CourseDescription    data={courseData} />            
        </div>
    )
}

export default SingleCourse


