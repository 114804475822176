import { Button, Checkbox, Col, Divider, Form, Image, Row } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import InputFormEmail from '../../../components/inputFormEmail/InputFormEmail'
import InputFormPassword from '../../../components/inputFormPassword/InputFormPassword'
import InputFormText from '../../../components/inputFormText/InputFormText'
import Title from 'antd/es/typography/Title'
import sigInImg from '../../../images/sigInImg.png'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
const SignUp = () => {
  const [showSteps, setShowSteps] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    {
      title: 'Professional Details',
      content: (
        <Step1 />
      )
    },
    {
      title: 'Course Information',
      content: (
        <Step2 />
      )
    },
    {
      title: 'Final Details',
      content: (
        <Step3 />
      )
    }
  ];

  const handleInitialSignUp = () => {
    setShowSteps(true);
  };

  const handleNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleSubmit = () => {
    console.log('Final submission');
  };

  if (!showSteps) {
    return (
      <div className='SignUp'>
        <Row align='middle' className='w-100 h-100'>

          <Col span={12} className=' form p-5' >


            <Title className='color-darkGrey  bold-500 font-32 mb-2'>Become a Great Lakes Online Instructor</Title>
            <Title className='color-grey text-start bold-400 font-18 mb-5'>Discover a supportive community of online instructors. Get instant access to all course creation resources.</Title>



            <Form name='signUp'>
              <InputFormText
              size='large'
                name={"firstName"}
                className='mt-3'
                span={24}
                validateTrigger={['onBlur']}
                Placeholder="Full Name"
              />
              <InputFormEmail
              size='large'
                name={"identifier"}
                className='mt-3'
                span={24}
                validateTrigger={['onBlur']}
                Placeholder="Email"
              />

              <InputFormPassword
              size='large'
                name={"password"}
                className='mt-3'
                withoutError={true}
                span={24} Placeholder="Password" />

              <Col span={24} className='text-start'>
                <Checkbox >
                  Remember me
                </Checkbox>
              </Col>

              <Col span={24}>

                <Button onClick={handleInitialSignUp} size='large' type='primary' className='mt-4' htmlType='submit' block>

                  Sign Up
                </Button>
              </Col>

              <Col span={24} className='text-center bold-400 color-grey mt-4'>
                By signing up, you agree to the Terms of use and Privacy Policy.



              </Col>
              <Col span={24} className='text-center bold-500 color-grey mt-5'>
                Already have an account?
                <Link
                  to={"/signIn"} className='color-primary ms-2'>
                  Sign In
                </Link>


              </Col>



            </Form>




          </Col>

          <Col span={12}>
            <Image src={sigInImg} width={"100%"} preview={false} height={"100%"} />
          </Col>


        </Row></div>
    )


  }

  return (
    <div className='SignUp '>
      <Form>
        <Row justify="center" align="middle" className='h-100'>
          <Col span={16} className='content'>
            {steps[currentStep].content}

          </Col>

          <Col span={24}>



            <Divider />
            <Row justify={'space-between'} align={'middle'} className='footer p-3'>

              <Col />

              <Title level={4} className='text-center color-grey bold-400 font-18'>Step {currentStep + 1} of {steps.length}</Title>



              <Col span={2}>
                <Button
                  type="primary"
                  block
                  onClick={currentStep === steps.length - 1 ? handleSubmit : handleNext}
                >
                  {currentStep === steps.length - 1 ? 'Submit' : 'Continue'}
                </Button>
              </Col>

            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};


export default SignUp