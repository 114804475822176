import { Button, Checkbox, Col, Form, Radio, Rate, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React, { useEffect } from 'react'
import { useWatch } from 'antd/es/form/Form'

const CourseAkramListFilters = (props) => {
    const { filter, setFilter, resetFilter, setPage, category,
        instructor } = props;

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setPage && setPage(1)
        setFilter && setFilter(values);
    };

    return (
        <>

            <Title className='color-darkGrey font-32 bold 500'>Filter by</Title>
            <Form
                name="CourseFilters_Form"
                onFinish={onFinish}
                form={form}
            >

                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Title className='font-20 color-darkGrey bold-500'>Category</Title>
                        <Form.Item name="category"
                        >
                            <Radio.Group  >
                                {category?.length > 0 && category?.map((item, index) => {
                                    return <Col key={index} span={24} className='mt-3' >
                                        <Radio value={item?.id}>{item?.slug}</Radio>
                                    </Col>
                                })}
                            </Radio.Group>

                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Title className='font-20 color-darkGrey bold-500'>Instructors</Title>
                        <Form.Item name="instructor"
                        >
                            <Radio.Group  >
                                {instructor?.length > 0 && instructor.map((item, index) => (
                                    <Col span={24} key={index} className='mt-3'>
                                        <Radio key={index} value={item.id}>{item.name}</Radio>
                                    </Col>
                                ))}
                            </Radio.Group>

                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Row justify={'start'} align={'middle'} gutter={[16, 10]}>
                            <Button type='link' htmlType='submit' className='color-primary' form='CourseFilters_Form'>Apply Filter</Button>
                            <br />
                            <Button
                                type='link'
                                className='font-14 color-darkGrey bold-500'
                                onClick={() => {
                                    resetFilter && resetFilter()
                                    form.resetFields()
                                }
                                }
                            >
                                Reset
                            </Button>

                        </Row>
                    </Col>
                </Row>

            </Form>

        </>
    )
}

export default CourseAkramListFilters