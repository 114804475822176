import { Col, Row } from 'antd'
import Search from 'antd/es/input/Search'
import Title from 'antd/es/typography/Title'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCoursesList } from '../../../actions/CoursesActions'
import CourseListFilters from '../CourseListFilters'
import '../CoursesList.scss'
import MyGrid from '../../../components/myGrid/MyGrid'

import { CourseCard } from '../CourseCard'
import CourseAkramListFilters from './CourseAkramListFilters'
import { CourseAkramCard } from './CourseAkramCard'


const CoursesListAkram = () => {

  const { programId } = useParams();

  // const handleMoreDetails = (courseId) => {
  //   navigate(`/course/${courseId}`);
  // };




  const [data, setData] = useState([])
  const [category, setCategory] = useState([])
  const [instructor, setInstructor] = useState([])



  const [filter, setFilter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(6)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState("")
  const [total, setTotal] = useState(0)


  const fillCoursesAkram = async () => {
    let params = {}
    let result = await getCoursesList()
    console.log("result", result);

  }

  useEffect(() => {
    fillCoursesAkram()
  }, [])



  const fill = async () => {
    setLoading(true)
    let params = {
      keyword: keyword ? keyword : "",
      page: page,
      par_page: pageSize,
      category: filter?.category,
      instructor: filter?.instructor,
      // approve_status:1
    }
    let result = await getCoursesList(params)
    if (result?.courses) {
      console.log("Courses Data", result);
      setData(result?.courses?.data)
      setCategory(result?.categories)
      setInstructor(result?.instructors)
      setTotal(result?.courses?.total)
    }
    setLoading(false)

  }

  let fetch = true
  useEffect(() => {
    if (fetch) {
      fill()
    }
    return () => {
      fetch = false
    }
  }, [keyword, filter, page])

  const resetPage = () => {
    setPage(1)
    setKeyword("")
    setFilter(null)
    setTotal(0)
  }


  const getCategoryNameByID = (id, type) => {
    let cat = category.find(item => item.id == id)
    switch (type) {
      case 1:
        return cat?.slug
      case 2:
        return cat
      default:
        return cat?.slug
    }
  }

  const getInstructorNameById = (id, type) => {
    let instructorfound = instructor.find(item => item.id == id)
    switch (type) {
      case 1:
        return instructorfound?.name
      case 2:
        return instructorfound
      default:
        return instructorfound?.name
    }
  }


  return (
    <>

      <Row className='content myContainer'>
        <Col span={4} className='filters'>
          <CourseAkramListFilters
            setFilter={setFilter}
            filter={filter}
            resetFilter={resetPage}
            setPage={setPage}
            category={category}
            instructor={instructor}
          />

        </Col>

        <Col span={20} className='courses'>
          <Row justify={'space-between'}>
            <Title className='bold-500 font-32 color-darkGrey'  >Explore Your Course</Title>
            <Col span={5}>
              <Search placeholder='Search' rootClassName='searchInput'
                onChange={(e) => { !e?.target?.value && setKeyword("") }}
                onSearch={(value) => { setPage(1); setKeyword(value) }} />
            </Col>


          </Row>
          <div className='mt-2' id='courses' >
            <MyGrid
              loading={loading}
              page={page}
              total={total}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              defaultPageSize={pageSize}
              data={data}
              showSizeChanger={false}
              emptyStyle={{ marginTop: 100 }}
              // GridStyle ={{minHeight: "85vh" , maxHeight:"calc(100vh - 100px)" ,overflowY:"scroll",overflowX:"hidden" ,paddingTop:"15px" }}
              // icon={<TbMapPlus size={100} />}
              title="No Courses Found"
              label="We’ll let you know when there will be something to update you."
              xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}
              gutter={[24, 46]}
              justify={"start"}
              renderItem={(course, index) => {
                return (
                  <CourseAkramCard
                    data={course}
                    key={index}
                    getCategoryNameByID={getCategoryNameByID}
                    getInstructorNameById={getInstructorNameById}

                  />
                )
              }}
            />

          </div>
        </Col>



      </Row>

    </>
  )
}

export default CoursesListAkram