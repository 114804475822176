import { Button, Card, Col, Image, Row, Tag } from 'antd'
import Search from 'antd/es/input/Search'
import Title from 'antd/es/typography/Title'
import React, { useEffect, useState } from 'react'
import laptop from '../../images/laptop.png'
import { FaStar } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import { coursesLevel, coursesPriceType, getCourses, getCoursesList, subtitlesCourse } from '../../actions/CoursesActions'
import CourseListFilters from './CourseListFilters'
import { generateCourseCode } from '../../utils'
import './CoursesList.scss'
import MyGrid from '../../components/myGrid/MyGrid'
import Paragraph from 'antd/es/typography/Paragraph'
import { HiMiniSignal } from 'react-icons/hi2'
import { MdOutlineEventSeat } from 'react-icons/md'
import { CourseCard } from './CourseCard'


const CoursesList = () => {

  const { programId } = useParams();

  // const handleMoreDetails = (courseId) => {
  //   navigate(`/course/${courseId}`);
  // };



  const [data, setData] = useState([])
  const [filter, setFilter] = useState(null)
  const [loading, setLoading] = useState(false)
  const [pageSize, setPageSize] = useState(6)
  const [page, setPage] = useState(1)
  const [keyword, setKeyword] = useState("")
  const [total, setTotal] = useState(0)


  const fill = async () => {
    setLoading(true)
    let params = {
      programId: programId,
      search: keyword,
      pageNumber: page,
      pageSize: pageSize,
      with_program: true,
      filter: filter
    }
    let result = await getCourses(params)
    if (result?.success) {
      console.log("Courses Data", result?.data);
      setData(result?.data)
      setTotal(result?.total)
    }
    setLoading(false)

  }

  let fetch = true
  useEffect(() => {
    if (fetch && programId) {
      fill()
    }
    return () => {
      fetch = false
    }
  }, [programId, keyword, filter, page])

  const resetPage = () => {
    setPage(1)
    setKeyword("")
    setFilter(null)
    setTotal(0)
  }


  useEffect(() => {
    resetPage()

  }, [programId])

  return (
    <>

      <Row className='content myContainer'>
        <Col span={4} className='filters'>
          <CourseListFilters
            setFilter={setFilter}
            filter={filter}
            resetFilter={resetPage}
            setPage={setPage}

          />

        </Col>

        <Col span={20} className='courses'>
          <Row justify={'space-between'}>
            <Title className='bold-500 font-32 color-darkGrey'  >Explore Your Course</Title>
            <Col span={5}>
              <Search placeholder='Search' rootClassName='searchInput'
                onChange={(e) => { !e?.target?.value && setKeyword("") }}
                onSearch={(value) => { setPage(1); setKeyword(value) }} />
            </Col>


          </Row>
          <div className='mt-2' id='courses' >
            <MyGrid
              loading={loading}
              page={page}
              total={total}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              defaultPageSize={pageSize}
              data={data}
              showSizeChanger={false}
              emptyStyle={{ marginTop: 100 }}
              // GridStyle ={{minHeight: "85vh" , maxHeight:"calc(100vh - 100px)" ,overflowY:"scroll",overflowX:"hidden" ,paddingTop:"15px" }}
              // icon={<TbMapPlus size={100} />}
              title="No Courses Found"
              label="We’ll let you know when there will be something to update you."
              xxl={8} xl={8} lg={12} md={12} sm={24} xs={24}
              gutter={[24, 46]}
              justify={"start"}
              renderItem={(course, index) => {
                return (
                  <CourseCard
                    data={course}
                    // key={course?.id}
                  
                  />
                )
              }}
            />

          </div>
        </Col>



      </Row>

    </>
  )
}

export default CoursesList