import { Col, Form, Input } from "antd";
import React from "react";

const InputFormPhoneNumber = (props) => {
  const { span, xl, xs, className, formItemClassName,withoutError, ...formProps } = props;
  const { name, required, placeholder, label, inputClassName, ...inputProps } =
    formProps;
  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${"please input valid "} ${
        placeholder ? placeholder : label
      }!`;
  const rules = [
    {
      required: required,
      message: !withoutError? errorMessage : "",
    },
  ];
  return (
    <Col span={span} xl={xl} xs={xs} className={className}>
      <Form.Item
        className={formItemClassName}
        name={name}
        required={required}
        placeholder={placeholder}
        label={label}
        rules={rules}
      >
        <Input type="number" className={inputClassName} {...inputProps} />
      </Form.Item>
    </Col>
  );
};
InputFormPhoneNumber.defaultProps = {
  name: "phoneNumber",
  nameCode: "phoneCode",
  placeholder: "Phone number",
  required: true,
  hasFeedback: true,
  allowClear: true,
  autoComplete: "off",
  bordered: true,

};

export default InputFormPhoneNumber;
