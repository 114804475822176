import { Button, Col } from "antd";
import React from "react";
import LoadingModal from "../loadingModal/LoadingModal";

const MyButtonPrimary = (props) => {
  const { children,saving,loading, className, btnClassName,rounded,disabled, span, xl, sm, md,loadingTitle } = props;
  return (
    <Col span={span} xl={xl} sm={sm} md={md} className={className}>
            <LoadingModal loading={saving} title={loadingTitle} />
      <Button 
      disabled={disabled} loading={saving||loading}
      block={props.block}
      type={props.type} htmlType={props.htmlType} className={`${btnClassName} ${rounded && "RoundedBtn"}`} {...props}>
        {children}
      </Button>
    </Col>
  );
};

MyButtonPrimary.defaultProps = {
  type: "primary",
  span:24,
  htmlType: "submit",
  saving:false
}


export default MyButtonPrimary;
