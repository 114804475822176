export const myTheme =
{
  "token": {
    "colorPrimary": "#FFB400",
    "colorInfo": "#0198c1"
  },

  "components": {
    "Input": {
      "inputFontSizeLG": 18,
      "controlHeightLG": 64,
      "colorBorder": "rgba(102,102,102,0.36)",
      "colorBgContainer": "rgb(248,248,248)"
    },
    "InputNumber": {
      "colorBgContainer": "rgb(248,248,248)",
      "colorBorder": "rgba(102,102,102,0.36)",
      "controlHeightLG": 64,
      "fontSizeLG": 18
    },
    "Select": {
      "colorBgContainer": "rgb(248,248,248)",
      "fontSizeLG": 18,
      "controlHeightLG": 64,
      "colorBorder": "rgba(102,102,102,0.34)"
    },
    "DatePicker": {
      "colorBgContainer": "rgb(248,248,248)",
      "colorBorder": "rgba(102,102,102,0.36)",
      "controlHeightLG": 64
    }
  }

}