import { Col, Form, Input } from "antd";
import React, { forwardRef } from "react";

const InputFormText = forwardRef((props, ref) => {
  const { span, xl, xs, className, formItemClassName, withoutError, ...formProps } = props;
  const { name, required, placeholder, label, inputClassName, ...inputProps } =
    formProps;
  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${"Please input a valid value! "}`;
  //  ${placeholder ? placeholder : label}
  const rules = [
    {
      required: required,
      message: !withoutError ? errorMessage : "",
    },
  ];
  return (
    <Col {...props} hidden={props.hidden} className={`${className} text-start`}>
      <Form.Item
        className={formItemClassName}
        name={name}
        required={required}
        placeholder={placeholder}
        label={label}
        
        // <span
        //   title={props.labelTitle ? props.labelTitle : label}
        // >
          
         
        
        // </span>
        rules={rules}

      >
        <Input
          ref={ref}
          id={props.id}
          className={inputClassName} {...inputProps} />
      </Form.Item>
    </Col>
  );
});






InputFormText.defaultProps = {
  required: false,
  hasFeedback: true,
  allowClear: true,
  type: "text",
  controls: false,
  bordered: true,
};

export default InputFormText;
