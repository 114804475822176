import { BrowserRouter as Router, Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import AppRoute from './appRoute/AppRoute';
import Parse from 'parse/dist/parse.min.js';
import { ConfigProvider } from 'antd';
import { myTheme } from './utils/theme';




function App() {
  Parse.initialize("JOCFkHKT69GqdrakSYkoyeGgB89BnG0JqGHRTtUo", "sC0R9z8gipoWKB6F1zjtB5fj5s9qIkhaLOQM5IOG"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
  Parse.serverURL = 'https://parseapi.back4app.com/'

  return (
    <BrowserRouter>
    
      <ConfigProvider
      theme={myTheme}
    >
     <AppRoute />
    </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
