import { Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import UploadFile from '../../../../components/uploadFile/UploadFile'
import { MdPersonOutline } from "react-icons/md";
import { FaRegAddressCard } from 'react-icons/fa';
import { BsPassport } from 'react-icons/bs';

const Step1 = () => {
    return (
        <>
            <Title className='color-darkGrey  bold-500 font-32 mb-2'>Welcome, Mulham</Title>
            <Title className='color-grey text-start bold-400 font-18 mb-5'>To set up your account, simply follow these 3 steps to get started and join our community.</Title>

            <Row justify={'space-between'} align={'middle'} className=''>
                <UploadFile span={8} icon={<MdPersonOutline size={100} color='#757575' />} title={'Upload Clear Photo'} />
                <UploadFile span={8} icon={<FaRegAddressCard size={100} color='#757575' />} title={'Upload CV (Resume)'} />
                <UploadFile span={8} icon={<BsPassport  size={100} color='#757575' />} title={'Upload Photo ID'} />

            </Row>


        </>
    )
}

export default Step1