import { LockOutlined } from "@ant-design/icons";
import { Col, Form, Input } from "antd";
import React from "react";
import { GoShieldSlash } from "react-icons/go";

const InputFormPassword = (props) => {
  const { span, xl, xs, className, formItemClassName,withoutError, ...formProps } = props;
  const {
    name,
    required,
    placeholder,
    prefix,
    label,
    inputClassName,
    initialValue,
    withoutErrorMessage,
    ...inputProps
  } = formProps;
  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${"please input a valid password! "} ${
        placeholder ? placeholder : label
      }!`;
  const rules = [
    {
      required: required,
      message: !withoutError ?  errorMessage : " ",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value?.length >=6) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(withoutError ? " " : " Please add more than 5 character"));
      },
    }),
  ];
  return (
    <Col span={span} xl={xl} xs={xs} className={`${className} text-start`} >
      <Form.Item
        className={formItemClassName}
        name={name}
        required={required}
        placeholder={placeholder}
        label={label}
        rules={rules}
        initialValue={initialValue}
      >
        <Input.Password
          className={`${inputClassName ? inputClassName : "" } w-100`}
          prefix={prefix}
          
          {...inputProps}
        />
      </Form.Item>
    </Col>
  );
};
InputFormPassword.defaultProps = {
  // prefix: <GoShieldSlash  color="#A4A4A4" size={24}/>,
  required: true,
  hasFeedback: true,
  allowClear: true,
  autoComplete: "on",
  bordered: true,
  withoutError : false,

};

export default InputFormPassword;
