import React, { useEffect, useState } from 'react'
import { Image, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import loadingGif from '../../images/loading/oldLight.gif'
import Title from 'antd/es/typography/Title';

const LoadingModal = (props) => {
    const { loading, icon, title, message , } = props

    const [timer, setTimer] = useState(false)
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) {
          setTimeout(() => {
            setLoaded(false)
          }, 2500);
        }
      }, [loaded]);

    return (
        <Modal 
        width={450}
        centered={true} 
        title={false} 
        open={loading || loaded} 
        footer={false} 
        zIndex={9999}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}>
         
            <div className='text-center p-5' >
                <div className='loaderCss' >
                  {/* {icon ? icon : <Image src={loadingGif} preview={false}  
                onLoad={() => setLoaded(true)}
                />} */}
                </div>
                <Title className='mt-5' level={2}>{title ? title : "Loading..."}</Title>
                <div>{message && message}</div>
            </div>
            
        </Modal>
    )
}

export default LoadingModal