import React from 'react'
import '../Auth.scss'
import { Button, Checkbox, Col, Form, Image, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import InputFormEmail from '../../../components/inputFormEmail/InputFormEmail'
import InputFormPassword from '../../../components/inputFormPassword/InputFormPassword'
import sigInImg from '../../../images/sigInImg.png'
import { Link } from 'react-router-dom'

const SignIn = () => {
  return (
    <div className='SignIn'>
      <Row align='middle' className='w-100 h-100'>

        <Col span={12} className=' form p-5' >


          <Title className='color-darkGrey text-center bold-500 font-32 mb-5'>Sign In</Title>



          <Form name='signIn'>
            <InputFormEmail
            size='large'
              name={"identifier"}
              className='mt-3'
              span={24}
              validateTrigger={['onBlur']}
              Placeholder="Email"
            />

            <InputFormPassword
            size='large'
              name={"password"}
              className='mt-3'
              withoutError={true}
              span={24} Placeholder="Password" />

            <Col span={24} className='text-start'>
              <Checkbox >
                Remember me
              </Checkbox>
            </Col>

            <Col span={24}>

              <Button size='large' type='primary' className='mt-4' htmlType='submit' block>

                Sign In
              </Button>
            </Col>

            <Col span={24} className='text-center bold-500 color-grey mt-4'>
              or
              <Link
                to={"/auth/signIn/forgetPassword"} className='color-primary ms-2'>
                Forgot Password?
              </Link>


            </Col>
            <Col span={24} className='text-center bold-500 color-grey mt-5' >
              Don't have an account?
              <Link
                to={"/signUp"} className='color-primary ms-2'>
                Sign Up
              </Link>


            </Col>



          </Form>




        </Col>
        <Col span={12}>
          <Image src={sigInImg} width={"100%"} preview={false} height={"100%"} />
        </Col>


      </Row>
    </div>
  )
}

export default SignIn