import React, { Fragment, useEffect, useState } from "react";
// import UtilNotify from "../../utils/UtilNotify";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";

import { Button, Card, Col, Form, Upload, message } from "antd";
// import { getBaseURL } from "../../utils/UrlHelper";
// import ApiStorage from "../../reducers/ApiStorage";
import "./UploadFile.scss";
import Text from "antd/es/typography/Text";
import MyButtonPrimary from "../myButtonPrimary/MyButtonPrimary";
const UploadFile = (props) => {
  const { span, xl, xs, className, formItemClassName, defaultFileName, withoutError, ...formProps } = props;

  const { name, required, placeholder, label, inputClassName, size, ...inputProps } =
    formProps;
  const { disabled, onChange, images, title, maxCount = 1, ...other } = props;
  const [fileName, setFileName] = useState()
  const [loading, setLoading] = useState(false)

  const URL_UPLOAD_IMAGE = "profile/uploadFile";
  // useEffect(() => {
  //   if (defaultFileName && typeof defaultFileName == "string") {
  //     setFileName(defaultFileName)
  //   }
  // }, [defaultFileName])
  // const handleOnChange = (info) => {
  //   if (info.file.status === 'uploading') {
  //     setLoading(true)
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     setLoading(false)

  //     if (info.file.response) {
  //       if (info.file.response?.code == "1") {
  //         UtilNotify.notifySuccess(`${info.file.name} file upload Done.`)
  //         props.onChange && props.onChange(info?.file?.response?.data?.name)
  //         setFileName(info.file.response.data.name)
  //       } else {
  //         setLoading(false)
  //         UtilNotify.notifyError(`${info.file.name} file upload failed with message ${info.file.response?.message}`)
  //         setFileName(null)
  //         props.onChange && props.onChange(null)


  //       }
  //     }
  //   } else if (info.file.status === 'error') {
  //     setLoading(false)
  //     UtilNotify.notifyError(`${info.file.name} file upload failed.`)

  //   }
  // }
  const errorMessage = props.errorMessage
    ? props.errorMessage
    : `${"please input a valid value! "} ${placeholder ? placeholder : label}!`;
  const rules = [
    {
      required: required,
      message: !withoutError ? errorMessage : "",
    },
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value) {
          setValidateError(false)
          return Promise.resolve();
        }
        setValidateError(true)
        return Promise.reject(new Error(''));
      },
    }),
  ];
  const [validateError, setValidateError] = useState(false)

  return (
    <Fragment className="UploadFile">
     <Col span={span} xl={xl} xs={xs} className={className}>
      <Form.Item
        className={formItemClassName}
        name={name}
        required={required}
        placeholder={placeholder}
        label={label}
        rules={rules}
      >
        <Upload
          // onChange={handleOnChange}
          maxCount={maxCount}
          beforeUpload={(file) => {
            if (props.CustomFileAllow) {
              const isPNG = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'application/pdf');
              if (!isPNG) {
                message.error(`${file.name} is not a allowed file`);
              }
              return isPNG || Upload.LIST_IGNORE;
            }
          }}
          multiple={false}
          name="file"
          accept="image/jpeg, image/png, image/jpg"
          // action={getBaseURL() + URL_UPLOAD_IMAGE}
          headers={{
            // authorization: `Bearer ${ApiStorage.getAccessToken()}`,
            osType: "1",
            osVersion: "web",
            deviceModel: "web",
            deviceManufacturer: "web",
            "Accept-Language": "en",
            Accept: "application/json",
          }}
          className="text-center"
          disabled={loading || disabled}
          showUploadList={false}
        >
     
            <Card
              size={size}
              ghost={true}
              loading={loading}

              {...other}

              className={`uploadBtn mb-3 ${validateError && "validateError"} w-100`}
            >
              {props.icon}

            </Card>
            <Text
              className={`${validateError && "validateErrorText "}`}
              style={{ maxWidth: "20ch" }}
              tooltip={fileName ? fileName : title}
              ellipsis={true} >
              {fileName ? fileName : title}
              {/* <span className="color-darkGrey font-18"> {props.title}</span> */}
            </Text>
       

        </Upload>
      </Form.Item>
      </Col>

    </Fragment>
  );
};
UploadFile.defaultProps = {
  required: true,
  accept: ".jpg, .jpeg, .png , .doc, .pdf, .PDF ",

};

export default UploadFile;
