import { Avatar, Card, Col, Collapse, Divider, Image, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import RecommendedCourses from './RecommendedCourses'

const CourseCurriculum = (props) => {
    const { data } = props

    const items = [
        {
            key: '1',
            label: 'This is panel header 1',
            children: <p>{}</p>,
        },
        {
            key: '2',
            label: 'This is panel header 2',
            children: <p>{}</p>,
        },
        {
            key: '3',
            label: 'This is panel header 3',
            children: <p>{}</p>,
        },
    ];
    return (
        <Row gutter={[32, 32]} className='CourseCurriculum'>

            <Col span={20}>
                <Title className='color-darkGrey bold-400 font-18 line-32'>
                    {data?.description}

                </Title>
            </Col>


            <Col span={12} >
                <Card className='cusCard'>
                    <Collapse items={items} defaultActiveKey={['1']} />
                </Card>
            </Col>
            {/* <Col span={12}>
                <Card className='cusCard'>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Title className='color-darkGrey bold-700 font-18 line-32'>
                                Instructor
                            </Title>
                        </Col>

                        <Col span={24}>
                            <Row align={'middle'} gutter={[16, 0]}>
                                <Col>
                                    <Avatar src={data?.instructor?.profile_image} size={52} />
                                </Col>
                                <Col>


                                    <Title className='color-darkGrey bold-500 font-18 line-32'>
                                        Dr. Alex Greenfield
                                    </Title>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={20}>
                            Ph.D. in Environmental Engineering, with over 15 years of experience in sustainable transportation research and urban mobility solutions.
                        </Col>

                        <Divider color='#FFB40033' />

                        <Col span={24}>
                            <Title className='color-darkGrey bold-700 font-18 line-32'>
                                Offered by
                            </Title>
                        </Col>

                        <Col span={24}>
                            <Row align={'middle'} gutter={[16, 0]}>
                                <Col >
                                    <div className='logo'>
                                        <Image src={vector} size={48} />
                                    </div>

                                </Col>
                                <Col>

                                    <Title className='color-darkGrey bold-500 font-18 line-32'>
                                        Great Lakes Online
                                    </Title>

                                </Col>
                            </Row>
                        </Col>


                    </Row>

                </Card>
            </Col> */}



            <Col span={24}>
            <RecommendedCourses/>
            </Col>
        </Row>
    )
}

export default CourseCurriculum