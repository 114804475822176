import { Form, Row } from 'antd'
import React from 'react'
import InputFormPhoneNumber from '../../../../components/inputFormPhoneNumber/InputFormPhoneNumber'
import InputFormText from '../../../../components/inputFormText/InputFormText'

const Step2 = () => {
  return (
    <>
      <Form >
        <Row >

          {/* <InputFormPhoneNumber

            name={"phone"}
            label="Mobile No."
            span={24}
            nameCode="phoneCode"
            // setCountryCode={setPhoneCountryCode}
            required={false}
            size={"large"}
          /> */}


          <InputFormText
           size='large'
            label="Phone Number"
            name={"firstName"}

            span={20}
            validateTrigger={['onBlur']}
            Placeholder="Phone Number"
          />
          <InputFormText
           size='large'
            label="Address"
            name={"firstName"}

            span={20}
            validateTrigger={['onBlur']}
            Placeholder="Street Address"
          />
          <InputFormText
           size='large'
            name={"firstName"}

            span={20}
            validateTrigger={['onBlur']}
            Placeholder="Street Address Line 2"
          />
          <InputFormText
           size='large'
            name={"firstName"}

            span={20}
            validateTrigger={['onBlur']}
            Placeholder="City"
          />
          <InputFormText
           size='large'
            name={"firstName"}

            span={20}
            validateTrigger={['onBlur']}
            Placeholder="State / Province"
          />
          <InputFormText
           size='large'
            name={"firstName"}

            span={20}
            validateTrigger={['onBlur']}
            Placeholder="Postal / Zip Code"
          />
        </Row>
      </Form>
    </>
  )
}

export default Step2