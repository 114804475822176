import { Card, Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { FaUserGraduate } from 'react-icons/fa'
import { IoIosCheckmarkCircle } from 'react-icons/io'
import { MdOutlineEventSeat } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'


const LearningPoint = ({ text }) => {
  return (
    <Col span={12}>
      <Row justify={'space-between'}>
        <Col span={1}>
          <IoIosCheckmarkCircle color='#376386' size={24} />
        </Col>
        <Col span={23}>
          <Title className='color-darkGrey bold-400 font-18 ms-2'>{text}</Title>
        </Col>
      </Row>
    </Col>
  );
};

const RequirementPoint = ({ text }) => {
  return (
    <Col span={24}>
      <Row gutter={5}>
        <Col >
          <TbPointFilled color='#565656' size={24} />
        </Col>
        <Col span={23}>
          <Title className='color-darkGrey bold-400 font-18 '>{text}</Title>
        </Col>
      </Row>
    </Col>
  );
};


const CourseOverview = ({ data }) => {
  return (
    <Row gutter={[24, 64]} justify={'space-between'} className='CourseOverview'>
      <div>
        <Col span={24}>
          <Title className='color-darkGrey bold-600 font-24 '>Course Description</Title>
        </Col>
        <Col span={20}>
          <Title className='color-darkGrey bold-400 font-18 line-32'>
            {data?.description}

          </Title>
        </Col>
      </div>

      <Col span={24}>

     

        <Col xxl={10}  lg={16} md={18} sm={18} className='mt-4'>
          <Row justify={'space-between'} gutter={64} >

            <Col span={12}>
              <Card rootClassName='courseFormatCard'>
                <Title className='color-darkGrey bold-400 font-20'> <FaUserGraduate size='20' color='#565656' />  Language

                </Title>
                <Title className='color-primary bold-700 font-20'> English </Title>

              </Card>
            </Col>
            <Col span={12}>
              <Card rootClassName='courseFormatCard'>
                <Title className='color-darkGrey bold-400 font-20'> <MdOutlineEventSeat size='20' color='#565656' />  Seats Available

                </Title>
                <Title className='color-primary bold-700 font-20'> 18 <span className='color-lightGrey'>/   {data?.max_seats_available ? data?.max_seats_available : 0}</span> </Title>

              </Card>
            </Col>
          </Row>
        </Col>
      </Col>


      <Col span={20}>
        <Title className='color-darkGrey bold-600 font-24 mb-4'>Outcomes</Title>

        <Row gutter={[0, 24]} align={'middle'} justify={'space-between'}>

          <Col span={20}>

            <Row gutter={[0, 24]} align={'middle'} justify={'space-between'}>
              {data?.course_objectives?.length > 0 &&
                data?.course_objectives?.map((item, index) => (
                  <LearningPoint key={index} text={item?.objective} />
                ))}
            </Row>
          </Col>


        </Row>
      </Col>

      <Col span={20}>

      

        <Row gutter={[0, 24]} align={'middle'} justify={'space-between'}>
        <Title className='color-darkGrey bold-600 font-24 mb-4'>Requirements</Title>
          {data?.course_requirements?.length
            > 0 &&

            data?.course_requirements
              ?.map((item, index) => (
                <RequirementPoint key={index} text={item?.requirement} />
              ))}

        </Row>

      </Col>
    </Row>
  )
}

export default CourseOverview