import axios from "axios";



let BASE_URL = `https://learnata.heliumcloud.co`;
const apiKey = `jPR7PwacjiMIWR5eDEy5vyESy4vFds`

export const getBaseURL = () => {
  return `${BASE_URL}/api`;
};


const adapter = axios.create({
  baseURL: getBaseURL(),
  // auth: {
  //   username: apiKey,
  //   password: ''
  // },
  headers: {
    // Authorization: `${apiKey}`,
    "Content-Type": "application/json",
    osType: "1",
    osVersion: "web",
    deviceModel: "web",
    deviceManufacturer: "web",
    Accept: "application/json",
    timezone: getTimeZone()
  },
  timeout: 120000,
});




export async function fetchFromUrlPOSTAsync(url, parameters = []) {
  let lang = "en"

  try {
    let dataPost = {
      device: "web",
      offset: getTimeZone(),
    };
    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    adapter.defaults.headers.common["Accept-Language"] = `${lang}`;

    
    let result = await adapter.post(url, dataPost);
    if (result.status === 401 || result.status === -401) {
      window.location.reload();
      window.localStorage.clear();
    }
    return result.data;

  } catch (error) {
    console.log("error = ", error);
    if(error?.response?.data?.code  == "-401"){
      window.localStorage.clear();
      window.location.href = "/en/HomePage";
    }
    return { success: false, message: error.message };
  }
}


export async function fetchFromUrlPUTAsync(url, parameters = []) {
  let lang = "en"

  try {
    let dataPost = {
      device: "web",
      offset: getTimeZone(),
    };
    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    let token = "";
    adapter.defaults.headers.common["Accept-Language"] = `${lang}`;

    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.put(url, dataPost);
    if (result.status === 401 || result.status === -401) {
      window.location.reload();
      window.localStorage.clear();
    }
    return result.data;

  } catch (error) {
    console.log("error = ", error);
    if(error?.response?.data?.code  == "-401"){
      window.localStorage.clear();
      window.location.href = "/en/HomePage";
    }
    return { success: false, message: error.message};
  }
}

export async function fetchFromUrlGETAsync(url, parameters = []) {
  let lang = "en"

  try {
    let dataPost = {
      // device: "web",
      // offset: getTimeZone(),
    };

    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    adapter.defaults.headers.common["Accept-Language"] = `${lang}`;
    let token = "";
    if (token) {
      adapter.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    } else {
      delete adapter.defaults.headers.common["Authorization"];
    }
    let result = await adapter.get(url, {
      params: dataPost,
    });
    if (result.status === 401 || result.status === -401) {
      window.location.reload();
      window.localStorage.clear();
    }

    return result.data;
  } catch (error) {
    console.log("error = ", error);
    if(error?.response?.data?.code  == "-401"){
      window.localStorage.clear();
      window.location.href = "/en/HomePage";
    }
    return { success: false, message: error.message };
  }
}


export async function fetchFromUrlDELETEAsync(url, parameters = []) {
  try {
    let dataPost = {
      // device: "web",
      // offset: getTimeZone(),
    };

    parameters.forEach((par) => {
      dataPost[par.key] = par.value;
    });
    
    let result = await adapter.delete(url, {
      params: dataPost,
    });
    return result.data;
  } catch (error) {
    console.log("error = ", error);
    // alert("Error From backEnd "+error.message)

    return { success: false, message: error.message };
  }
}


function getTimeZone() {
  let offset = new Date().getTimezoneOffset();
  const sign = offset >= 0 ? '-' : '+';
  const result = `${sign} ${Math.abs(offset)}`;
  return result;
}



export default adapter;
