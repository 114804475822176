import { Avatar, Card, Col, Descriptions, Row } from 'antd'
import DescriptionsItem from 'antd/es/descriptions/Item'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { FaCertificate } from 'react-icons/fa'
import { IoDownloadSharp } from 'react-icons/io5'
import { MdDiscount, MdOutlineEventSeat } from 'react-icons/md'
import { getImageUrl } from '../../../utils'




const CourseAkramOverview = ({ data }) => {
  return (
    <div className='p-2' >
      <Row gutter={[24, 64]} justify={'space-between'} className='CourseOverview'>
        <div>
          <Col span={24}>
            <Title className='color-darkGrey bold-600 font-24 '>Course Description</Title>
          </Col>
          <Col span={20}>
            <Title className='color-darkGrey bold-400 font-18 line-32'>
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.course?.description,
                }}
              />
            </Title>
          </Col>
        </div>

        <Col span={24}>

          <Col span={24}>
            <Title className='color-darkGrey bold-600 font-24 '>Course Format</Title>
          </Col>

          <Col xxl={10} lg={16} md={18} sm={18} className='mt-4'>
            <Row justify={'start'} gutter={5} >
              <Col >
                <Card rootClassName='courseFormatCard'>
                  <Title className='color-darkGrey bold-400 font-20'> <MdOutlineEventSeat size='20' color='#565656' />  Seats Available
                  </Title>
                  <Title className='color-primary bold-700 font-20'> {data?.course?.enrollments_count} <span className='color-lightGrey'>/   {data?.course?.capacity ? data?.course?.capacity : 0}</span> </Title>
                </Card>
              </Col>
              <Col >
                <Card rootClassName='courseFormatCard'>
                  <Title className='color-darkGrey bold-400 font-20'> <FaCertificate size='20' color='#565656' />  Certificate
                  </Title>
                  <Title className='color-primary bold-700 font-20'> {data?.course?.certificate ? data?.course?.certificate : 0} </Title>
                </Card>
              </Col>

              <Col >
                <Card rootClassName='courseFormatCard'>
                  <Title className='color-darkGrey bold-400 font-20'> <MdDiscount size='20' color='#565656' />  Discount
                  </Title>
                  <Title className='color-primary bold-700 font-20'> {data?.course?.discount ? data?.course?.discount : 0} </Title>
                </Card>
              </Col>
              <Col >
                <Card rootClassName='courseFormatCard'>
                  <Title className='color-darkGrey bold-400 font-20'> <IoDownloadSharp size='20' color='#565656' />  Downloadable
                  </Title>
                  <Title className='color-primary bold-700 font-20'> {data?.course?.downloadable ? "Yes" : "No"} </Title>
                </Card>
              </Col>





            </Row>
          </Col>
        </Col>


        <Col span={20} >
          <div className='p-2' >
            <Title className='color-darkGrey bold-600 font-24 mb-4'>Instructor</Title>
            <Avatar
              src={getImageUrl(data?.instructor?.image)}
              style={{ backgroundColor: '#87d068' }}
            >
              {data?.instructor?.name[0]}
            </Avatar>
            <Descriptions>
              <DescriptionsItem label="Name">
                {data?.instructor?.name}
              </DescriptionsItem>
              <DescriptionsItem label="Gender">
                {data?.instructor?.gender}
              </DescriptionsItem>
              <DescriptionsItem label="Age">
                {data?.instructor?.age}
              </DescriptionsItem>
              <DescriptionsItem label="City">
                {data?.instructor?.city}
              </DescriptionsItem>

              <DescriptionsItem label="Job Title">
                {data?.instructor?.job_title}
              </DescriptionsItem>
            </Descriptions>
            <Descriptions>
              <DescriptionsItem label="Bio">
                {data?.instructor?.bio}
              </DescriptionsItem>
            </Descriptions>
          </div>
        </Col>


      </Row>
    </div>
  )
}

export default CourseAkramOverview