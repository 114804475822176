import { Button, Col, Image, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { GoClockFill } from 'react-icons/go';
import { HiMiniDocumentDuplicate, HiMiniSignal } from 'react-icons/hi2'
import { ImFilesEmpty } from 'react-icons/im';
import { MdOutlineSignalCellularAlt } from 'react-icons/md';
import { RiGraduationCapFill } from 'react-icons/ri';
import trans from '../../images/transprtation.jpeg'
import { getCoursesFormatLabel, getCoursesLevelLabel } from '../../actions/CoursesActions';



const IconRow = ({ icon, title }) => {
    return (
        <Col>
            <Row align={'middle'} className='mt-5 mb-5' gutter={5}>
                <Col >
                    {icon}
                </Col>
                <Col >
                    <Title className='color-white bold-400 font-16 mb-0'>{title}</Title>
                </Col>
            </Row>
        </Col>
    );
};
const SingleCourseHero = (props) => {
    const { data } = props
    return (
        <div className='SingleCourseHero myContainer'>


            <Row
                gutter={[24, 10]}
                justify={'space-between'}
            >
                <Col xl={12} lg={13} md={13} sm={24} xs={24}>
                    <Title className='color-white bold-600 font-40'>{data?.title}</Title>
                    <Title className='color-white bold-400 font-24'>{data?.note}</Title>

                    <Row justify={'space-between'}>
                        <IconRow
                            icon={<HiMiniSignal color='#FFB400' size={24} />}
                            title={data?.course_format ? getCoursesFormatLabel(data?.course_format) : `Unknown`
                            }
                        />
                        <IconRow
                            icon={<RiGraduationCapFill color='#FFB400' size={24} />}
                            title="156 +Students"
                        />
                        <IconRow
                            icon={<MdOutlineSignalCellularAlt color='#FFB400' size={24} />}
                            title={data?.level ? getCoursesLevelLabel(data?.level) : `Unknown`
                            }
                        />
                        <IconRow
                            icon={<GoClockFill color='#FFB400' size={24} />}
                            title={`${data?.duration} Hours`}
                        />
                        <IconRow
                            icon={<HiMiniDocumentDuplicate color='#FFB400' size={24} />}
                            title="20 Lessons"
                        />
                    </Row>
                    <Button type='primary' rootClassName='cusBtn'><span>Enroll Now</span>
                        <span>${data?.price}</span> </Button>
                    <Button type='link' className='color-white' > <span></span>Save for Later</Button>
                </Col>
                <Col xl={11} lg={10} md={10} sm={24} xs={24}>
                    <div style={{ aspectRatio: '16/9' }}>
                        <Image
                            src={data?.cover_image}
                            alt=''
                            className='cusImg radius-8'
                            width={'100%'}
                            rootClassName='fullHight'
                            preview={false}
                        />

                        <svg className='logo' width="45" height="48" viewBox="0 0 45 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.01155 45.8366C1.15738 45.8272 0.464766 45.1417 0.446439 44.2877L0.000401905 23.5029C-0.0301229 22.0805 1.6853 21.343 2.69655 22.3438L23.9308 43.3586C24.9421 44.3594 24.2224 46.0824 22.7998 46.0667L2.01155 45.8366Z" fill="url(#paint0_linear_382_67)" />
                            <path d="M1.16622 15.5424C1.05341 15.4283 0.98813 15.2756 0.983585 15.1152L0.621215 2.3252C0.605909 1.78498 1.04382 1.34088 1.5842 1.3486L14.7165 1.53629C14.8826 1.53866 15.041 1.60617 15.1578 1.72427L27.3649 14.0728C27.9796 14.6945 27.9738 15.6968 27.352 16.3114L15.6186 27.9105C14.9969 28.5252 13.9946 28.5194 13.38 27.8976L1.16622 15.5424Z" fill="url(#paint1_linear_382_67)" />
                            <path d="M17.6199 31.7335L31.0938 17.8959L44.495 30.9449C41.5971 36.7578 36.9828 41.5397 31.277 44.6432L31.021 44.7825L17.6199 31.7335Z" fill="url(#paint2_linear_382_67)" />
                            <path d="M39.9703 20.0634L26.0487 6.65658L21.0796 1.07617L24.5378 1.07617C28.9481 1.07617 33.2478 2.45593 36.8345 5.02212L37.0685 5.18947C38.4295 6.16326 39.6557 7.31288 40.7151 8.6084C43.717 12.2792 45.2351 16.9411 44.9704 21.6756L44.7848 24.9948L39.9703 20.0634Z" fill="url(#paint3_linear_382_67)" />
                            <defs>
                                <linearGradient id="paint0_linear_382_67" x1="41.3621" y1="22.56" x2="10.6617" y2="34.4391" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#0FB3ED" />
                                    <stop offset="0.996" stop-color="#0096D8" />
                                    <stop offset="1" stop-color="#0096D8" />
                                </linearGradient>
                                <linearGradient id="paint1_linear_382_67" x1="45" y1="23.5715" x2="0" y2="23.5715" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#565656" />
                                    <stop offset="1" stop-color="#8C8383" />
                                </linearGradient>
                                <linearGradient id="paint2_linear_382_67" x1="10.3235" y1="46.0668" x2="42.7015" y2="31.1644" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#0FB3ED" />
                                    <stop offset="1" stop-color="#164EAB" />
                                </linearGradient>
                                <linearGradient id="paint3_linear_382_67" x1="28.517" y1="1.15502" x2="34.1174" y2="24.8384" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#FFD056" />
                                    <stop offset="1" stop-color="#FFB400" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                </Col>

            </Row>
        </div>
    )
}

export default SingleCourseHero