import { Button, Col, Form, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import InputFormEmail from '../../../components/inputFormEmail/InputFormEmail'
import { Link, useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
    const navigate = useNavigate()
    return (
        <div className='SignIn myContainer' >
            <Form name='forget-password'>
                <Row align={'middle'} justify={'center'}>
                    <Col span={24}>
                        <Title className='color-darkGrey text-center bold-500 font-32 mb-5'>Forgot Password</Title>

                    </Col>


                    <Col span={10} className='text-center'>
                        <Title className='font-18 bold-400 color-grey'>Enter the email address you use on Great Lakes Online. We'll send you a link to reset your password.</Title>
                    </Col>
                    <Col span={24} />


                    <InputFormEmail
                     size='large'
                        name={"identifier"}
                        className='mt-5'
                        span={10}
                        validateTrigger={['onBlur']}
                        Placeholder="name@email.com"
                    />
                          <Col span={24} />

                    <Col span={10}>

                        <Button size='large' type='primary' className='mt-4' htmlType='submit' block  onClick={() => { navigate("/auth/signIn/Resetpassword") }}>

                            Reset Password
                        </Button>
                    </Col>

                    <Col span={24} className='text-center bold-500 color-grey mt-4'>
                        or
                        <Link
                            to={"/signIn"} className='color-primary ms-2'>
                            Sign In
                        </Link>


                    </Col>



                </Row>



            </Form>

        </div>
    )
}

export default ForgotPassword