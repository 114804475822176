import { Button, Col, Form, Row } from 'antd'
import React from 'react'
import InputFormPassword from '../../../components/inputFormPassword/InputFormPassword'
import InputFormConfirmPassword from '../../../components/inputFormPassword/InputFormConfirmPassword'
import { Link, useNavigate } from 'react-router-dom'
import Title from 'antd/es/typography/Title'

const Resetpassword = () => {

    const navigate = useNavigate()
    return (
        <div className='SignIn myContainer'>

            <Form name='forget-password' layout='vertical'>
                <Row align={'middle'} justify={'center'}>
                    <Col span={24}>
                        <Title className='color-darkGrey text-center bold-500 font-32 mb-5'>Reset your password</Title>

                    </Col>


                    <Col span={10} className='text-center'>
                        <Title className='font-18 bold-400 color-grey'>Changing password for malwerea@myglo.net          </Title>
                    </Col>
                    <Col span={24} />


                    <InputFormPassword
                     size='large'
                        name={"password"}
                        label="Password"
                        className='mt-3'
                        withoutError={true}
                        span={10} Placeholder="Password" />
                    <Col span={24} />

                    <InputFormConfirmPassword
                     size='large'
                    label="New Password Confirm"
                        name={"password"}
                        className='mt-3'
                        withoutError={true}
                        span={10} Placeholder="Confirm Password" />
                    <Col span={24} />

                    <Col span={10}>

                        <Button size='large' type='primary' className='mt-4' htmlType='submit' block onClick={() => { navigate("/auth/signIn/Resetpassword") }}>

                            Change Password
                        </Button>
                    </Col>

                    <Col span={24} className='text-center bold-500 color-grey mt-4'>
                        or
                        <Link
                            to={"/signIn"} className='color-primary ms-2'>
                            Sign In
                        </Link>


                    </Col>



                </Row>



            </Form>

        </div>
    )
}

export default Resetpassword