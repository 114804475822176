import { Card, Col, Row } from 'antd'
import Title from 'antd/es/typography/Title'
import React from 'react'
import { BiSupport } from 'react-icons/bi';
import { GrGroup } from 'react-icons/gr';

import { PiGraduationCap } from "react-icons/pi";


const FeatureCard = ({ title, description, icon }) => {
    return (
        <Col className='text-center' span={7} >
            <Row justify={'center'} className='text-center' >
                <Col >
                <Card className='icon'>{icon}</Card>
                </Col>
            </Row>
         
            <Title className='title'>{title}</Title>
            <Title className='sub'>{description}</Title>
        </Col>
    );
};


export const WhyGreatLakes = (props) => {
    const { data } = props

    const icons = [
        <PiGraduationCap />,
        <GrGroup />,
        <BiSupport />
    ];
    return (
        <div className='WhyGreatLakes myContainer' id="greatLakes">
         



            <Card className='myCard'>
                <Row gutter={30} justify={'space-between'} align={'middle'}>
                    <Col span={24} className='text-center mb-5'>
                        <Title className='bigTitle'>{data?.title}</Title>
                        <Title className='subTitle'>{data?.description}</Title>
                    </Col>

                    {
                        data?.children_items?.length > 0 &&


                        data?.children_items.map((item, index) => (
                            <React.Fragment key={item.id}>



                                <FeatureCard
                                    title={item.title}
                                    description={item.description}
                                    icon={icons[index]}
                                />

                                {index < data.children_items.length - 1 && (
                                    <Col>
                                        <svg width="7" height="138" viewBox="0 0 7 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.5 3.5L3.49999 134.5" stroke="url(#paint0_linear_139_13662)" stroke-opacity="0.2" stroke-width="6" stroke-linecap="round" />
                                            <defs>
                                                <linearGradient id="paint0_linear_139_13662" x1="48.5" y1="42" x2="-70" y2="84.5" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="white" />
                                                    <stop offset="1" stop-color="var(--color-grey)" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </Col>
                                )}
                            </React.Fragment>
                        ))}
                </Row>
            </Card>

        </div>
    )
}
