import { Col, Form, Input } from "antd";
import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import { TfiEmail } from "react-icons/tfi";

const InputFormEmail = (props) => {

  const { span, xl, xs, className, formItemClassName, withoutError, ...formProps } = props;

  const {
    name,
    required,
    placeholder,
    prefix,
    label,
    inputClassName,
    ...inputProps
  } = formProps;

  const errorMessage = props.errorMessage ?
    props.errorMessage
    :
    `${("Please Input")} ${props.placeholder ? props.placeholder : props.label ? props.label : "Valid Value"}!`;

  return (
    <Col span={span} xl={xl} xs={xs} className={className} hidden={props.hidden}>
      <Form.Item
        className={formItemClassName}
        name={name}
        required={required}
        validateTrigger={props.validateTrigger}
        // placeholder={placeholder}
        label={label}
        allowClear={props.allowClear}
        rules={[
          { required: required, message: !withoutError ? errorMessage : "", type: props.type },
        ]}
      >
        <Input prefix={prefix} type={props.type} className={`${inputClassName} w-100`} {...inputProps} />
      </Form.Item>
    </Col>
  );
};
InputFormEmail.defaultProps = {
  // prefix: <HiOutlineMail color="#A4A4A4" size={24} />,
  required: true,
  hasFeedback: true,
  allowClear: true,
  type: "email",
  autoComplete: "new-email",
  withoutError: false,
  bordered: true,

};

export default InputFormEmail;
